<template>
    <div class="login-wrap">
        <div class="ms-login">
            <div class="ms-login-left"></div>
            <div class="ms-login-right">
                <div class="login-logo">
                    <svg-icon class="svg-icon" icon-class="icon_login_logo" size="48"></svg-icon>
                </div>
                <div class="login-form">
                    <p class="login-title">登录</p>
                    <el-form :model="param" :rules="rules" ref="param" label-width="0px" class="ms-content">
                        <el-form-item prop="username">
                            <p class="item-lable">账号</p>
                            <el-input v-model="param.username" placeholder="请输入账号" clearable></el-input>
                        </el-form-item>
                        <el-form-item prop="password">
                            <p class="item-lable">密码</p>
                            <el-input :type="password" placeholder="请输入密码" class="password"  @blur="onBlur" clearable v-model="param.password" @keyup.enter.native="submitForm()">
                                <i slot="suffix" @click="showPass" class="show-pass">
                                    <svg-icon class="svg-icon" v-if="password == 'password'" icon-class="icon_see_password" size="16"></svg-icon>
                                    <svg-icon class="svg-icon" v-if="password == 'text'" icon-class="icon_hidden_password" size="16"></svg-icon>
                                </i>
                            </el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-switch
                                v-model="param.remenberPassword"
                                active-text="记住密码"
                                >
                            </el-switch>
                        </el-form-item>
                        <div class="login-btn"  @click="submitForm()">
                            登录
                        </div>
                    </el-form> 
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { setPassword,setUsername,getUsername,getPassword } from '@/utils/cookie'
export default {
    data: function() {
        var passwordValidate = (rule, value, callback) => {
            if (this.smsMsg) {
                callback(new Error(this.smsMsg));
            }else{
                callback();
            }
        };
        return {
            param: {
                username: '',
                password: '',
                remenberPassword: true
            },
            password:'password',
            smsMsg:'', //接口请求处的提示语
            rules: {
                // username: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
                // password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
                username: [{ required: true, message: '请输入账号' }],
                password: [{ required: true, message: '请输入密码' },{validator: passwordValidate}],
            },
        };
    },
    created(){
        //判断是否记住密码
        if(localStorage.getItem('remenberPassword') == 'true') {
            this.param.remenberPassword = true;
            this.param.username = getUsername() ? getUsername() : '';
            this.param.password = this.RSA.decryptRsaCode(getPassword()) ? this.RSA.decryptRsaCode(getPassword()):'';
        } else {
            this.param.remenberPassword = false;
        }
    },
    methods: {
        submitForm() {
            this.$refs.param.validate(valid => {
                if (valid) {
                    let loginObj = JSON.parse(JSON.stringify(this.param));
                    loginObj.password = this.RSA.getRsaCode(loginObj.password);
                    this.$store.dispatch('user/login', loginObj).then( (res) => {
                        if( res.code ===  200 ) {
                            this.param.remenberPassword ? setPassword(loginObj.password) : '';
                            localStorage.setItem('remenberPassword', this.param.remenberPassword);
                            // 登录成功后进行路由跳转
                            this.$router.push({ path: '/' });
                        }else{
                            this.smsMsg = res.data.message;
                            this.$refs.param.validateField(['password']);
                            this.smsMsg = '';
                        }
                    }).catch((err) => {
                    });
                } else {
                    return false;
                }
            });
        },
        showPass(){
            //点击图标是密码隐藏或显示
            if (this.password == 'text') {
                this.password = 'password';
            } else {
                this.password = 'text';
            }
        },
        //密码失焦事件
        onBlur(){
            this.password = 'password';
        },
    },
};
</script>

<style lang="scss" scoped>
    .login-wrap {
        position: relative;
        width: 100%;
        height: 100%;
        background: url('~@/assets/image/login/bg_signin@2x.png');
        background-size: 100%;
    }
    .ms-login {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        width: 1080px;
        height: 560px;
        background: #FFFFFF;
        box-shadow: 0px 12px 48px 16px rgba(0, 0, 0, 0.03), 0px 9px 28px 0px rgba(0, 0, 0, 0.05), 0px 6px 16px -8px rgba(0, 0, 0, 0.08);
        border-radius: 20px;
        overflow: hidden;
        .ms-login-left  {
            height: 100%;
            width: 600px;
            background: url('~@/assets/image/login/image_signin@2x.png');
            background-size: 100%;
        }
        .ms-login-right {
            position: relative;
            width: 480px;
            height: 100%;
            padding: 32px;
            box-sizing: border-box;
            .login-logo {
                position: absolute;
                width: 48px;
                height: 48px;
                top: 12px;
                right: 12px;
            }
            .login-form {
                width: 100%;
                height: 100%;
                padding: 59px 48px 0 48px;
                box-sizing: border-box;
                .login-title {
                    margin-bottom: 38px;
                    font-size: 24px;
                    font-weight: 500;
                    color: #262626;
                }
                .item-lable {
                    font-size: 16px;
                    font-weight: 400;
                    color: #595959;
                }
                .login-btn {
                    width: 100%;
                    height: 48px;
                    line-height: 48px;
                    text-align: center;
                    font-size: 16px;
                    color: #FFFFFF;
                    background: #3270FF;
                    border-radius: 4px;
                    margin-top: 75px;
                    cursor: pointer;
                }
                
            }
        }
    }
</style>
<style lang="scss">
    .login-form {
        .el-switch__label {
            color:#595959 !important 
        }
        .el-switch__label.is-active {
            color:#595959 !important ;
        }
        .el-form-item {
            margin-bottom: 24px;
        }
        .el-input__inner {
            padding: 0 15px 0 0;
            border-top-width:0;
            border-left-width:0;
            border-right-width:0;
            border-radius:0;
            height: 26px;
            line-height: 26px;
        }
        .password {
            .el-input__inner {
                padding-right: 45px;
            }
            .show-pass {
                width:16px;
                height:16px; 
                position:relative;
                top:0px;
                right: -5px;
            }
            .el-icon-circle-close::before {
                position: relative;
                right: 40px !important;
                top: -10px;
                font-size: 18px;
                width: 16px;
                height: 16px;
            }
        }

        .el-input__suffix-inner {
            display: flex;
            align-items: center;
        }
        .el-icon-circle-close {
            width: 0;
            height: 0;
        }
        .el-icon-circle-close::before {
            position: relative;
            right: 10px !important;
            top: -3px;
            font-size: 18px;
            width: 16px;
            height: 16px;
        }
        .el-form-item__content {
            line-height: 26px;
        }
    }
    

</style>